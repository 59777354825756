.links1 {
  /* font-size: 2.5vh;
  border: 1px solid;
  background-color: #333;
  position: relative;
  background-size: cover;
  background-position: center; */

  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  font-weight: 700;
  transition: 0.5s;
}
.lin1 {
  display: none;
}

.lin {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
  transition: 0.5s;
  background-color: #131513;
}
.myLinks {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.cont2 {
  /* display: block;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  width: fit-content;
  border: 1px solid; */

  width: 100vw;
}

.val {
  display: inline-flex;
  margin-left: 20px;
}
.links {
  padding: 8px;
  text-decoration: none;
  font-size: 3vh;
  color: #fffbfb;
  display: block;
  transition: 0.3s;
  margin-top: 10vh;
  text-align: center;
  width: 100vw;
}

.socials {
  display: block;
  display: inline-flex;
  margin-right: 1vh;
  font-size: 5vh;
}

.icon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  margin-right: 5vw;
  margin-top: 3vw;
  text-align: center;
  padding-top: 1vh;
  border-radius: 50%;
  width: 9vh;
  height: 9vh;
  font-size: 10pc;
  color: rgb(255, 255, 255);
}

.icons {
  font-size: 4vh;
}
.iconhead {
  font-size: 5vh;
}
.head {
  font-size: 4.5vh;
  display: inline;
  position: relative;
}
.head2 {
  font-size: 2.5vh;
  display: inline;
  position: relative;
  font-weight: 900;
  color: rgb(255, 255, 255);
  font-family: "YoungSerifRegular";
}

.head {
  font-size: 4.5vh;
  display: inline;
  position: relative;
  font-weight: 900;
  font-style: oblique;
}
.active {
  background-color: #6f6f6f;
  color: white;
}
.para {
  color: yellow;
  display: inline-block;
  font-weight: 900;
}
.para2 {
  font-size: 2.5vh;
}

.logo {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: 1000;
  margin: 0.5vw;
  margin-left: 5vw;
}
.home1 {
  display: inline;
}
.home {
  display: inline-block;
  margin: 1vw;
  text-align: center;
}
.button {
  width: 10em;
  height: 5vh;
  margin-bottom: 5vh;
  border-radius: 99px;
  background-color: rgb(241, 145, 0);
}
.bout {
  text-align: left;
  text-decoration: underline;
  margin-bottom: 5vh;
}
.button2 {
  width: 20vh;
  height: 6vh;
  margin-bottom: 5vh;
  border-radius: 99px;
  border: 1px solid rgb(241, 145, 0);
  background-color: transparent;
  color: rgb(241, 145, 0);
  font-size: 2vh;
  font-weight: 500;
}
.homes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  font-style: oblique;
  position: relative;
}

.typical {
  color: rgb(255, 255, 255);
}

/* :root {
  color: rgb(255, 255, 255);
  background-image: url("./images/BACKGROUND.jpg");
  background-size: cover;
  height: 100%;
  margin: 0;
} */
.image {
  border-radius: 50%;
  width: 30vh;
  height: 30vh;
  margin-left: 40px;
}
.buttonabout {
  font-weight: 600;
  padding: 1vh 2vh;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24vh;
  margin: 0 auto;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: 0.3s ease-in-out;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  /* background-color: rgb(241, 145, 0); */
  border: 1px solid rgb(241, 145, 0);
  background-color: transparent;
  font-size: 2vh;
  color: rgb(241, 145, 0);
}

/* .aboutmain {
  background-image: url("./images/MEE.JPEG");

  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
} */

/* .abouttext {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
} */

.contactinfo {
  font-size: 2.5vh;
  font-style: oblique;
  padding-top: 15vh;
  font-family: "InconsolataRegular";
}

.texts {
  text-align: center;
  margin-bottom: 10vh;
}
.col1 {
  margin-bottom: 5vh;
}
.col {
  margin-bottom: 4vh;
}
.col1s {
  font-size: 2.8vh;
}
.mytexxt {
  text-align: center;
  margin-top: 2vh;
}

.closebutton {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.cardtext {
  color: rgb(255, 255, 255);
  text-align: center;
  border: 1px solid;
}
.workimg {
  margin-top: 2vh;
}
.head1 {
  font-size: 6.5vh;
  font-style: oblique;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.typhead {
  font-size: 2.5vh;
}
.textdiv {
  height: fit-content;
  text-align: center;

  font-size: 1.7vh;
  font-family: "Times New Roman", Times, serif;
  width: 45vh;
  text-align: center;
}

.notlink {
  text-decoration: none;
  color: rgb(241, 145, 0);
}

.conact {
  margin-top: 20vh;
}
.aboutpage {
  padding-top: 15vh;
  text-align: center;
  font-size: 2.5vh;
  font-family: "InconsolataRegular";
}
.workPage {
  padding-top: 15vh;
  text-align: center;
  font-size: 2.5vh;
  font-family: "InconsolataRegular";
}

.name {
  font-family: "InconsolataRegular";
  font-weight: 800;
  font-size: 5vh;
  display: inline;
  color: rgb(241, 145, 0);
}
@media screen and (max-width: 1024px) {
  .buttonabout {
    font-weight: 600;
    padding: 1vh 2vh;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    transition: 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    border: 1px solid rgb(241, 145, 0);
    background-color: transparent;
    font-size: 2vh;
  }
  .icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    margin-right: 5vw;
    margin-top: 3vw;
    text-align: center;
    padding-top: 1vh;
    border-radius: 50%;
    width: 9vh;
    height: 9vh;
    font-size: 10pc;
    color: rgb(255, 255, 255);
  }
  .iconic {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    position: fixed;
    margin-right: 5vw;
    margin-top: 3vw;
    text-align: center;
    padding-top: 1vh;
    border-radius: 50%;
    width: 9vh;
    height: 9vh;
    font-size: 10pc;
    color: rgb(255, 255, 255);
    background-color: #454241;
  }

  .button2 {
    width: 20vh;
    height: 6vh;
    margin-bottom: 5vh;
    border-radius: 99px;
    border: 1px solid rgb(241, 145, 0);
    background-color: transparent;
    color: rgb(241, 145, 0);
    font-size: 2vh;
    font-weight: 700;
  }
}
.about {
  background-color: transparent;
  text-align: left;
  margin-top: 5vh;
  padding-bottom: 2vh;
}

.cont {
  display: inline;
  color: #fffbfb;
}
.cont :hover {
  background-color: #6f6f6f;
}
.works {
  display: inline-block;
}

.worksimg {
  float: right;
  width: 10vh;
  border-radius: 99px;
}
.mainwork {
  position: relative;
  font-size: 2vh;

  height: fit-content;
  padding: 5vh;
}
.button3 {
  width: 20vh;
  height: 6vh;
  margin-bottom: 5vh;
  border-radius: 99px;
  border: 1px solid rgb(241, 145, 0);
  background-color: transparent;
  color: rgb(241, 145, 0);
  font-size: 2vh;
  font-weight: 500;
  text-decoration: none;
  margin-top: 5vh;
}
.navvi {
  text-decoration: none;
  color: rgb(241, 145, 0);
}
.forms {
  height: 5vh;
}
.skillss {
  text-align: center;
}
.linkedin {
  color: rgb(10, 102, 194);
}
.instagram {
  background-image: linear-gradient(
    45deg,
    #d48989,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  color: white;
}
.github {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border-radius: 99px;
}
.cards {
  background-image: url("./images/sparkedinnovation.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 40vh;
  background-size: contain;
  background-position: center;
  background-color: white;
  margin-top: 20px;
}
.cards:hover {
  border: 2px solid orange;
}

.modall {
  color: #131513;
  margin-top: 40vh;
}
.cards2 {
  background-image: url("./images/nextgen.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 40vh;
  background-size: cover;

  margin-top: 20px;
}
.cards2:hover {
  border: 2px solid orange;
}

.cards3 {
  background-image: url("./images/nextgen.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 40vh;
  background-size: cover;

  margin-top: 20px;
}
.cards3:hover {
  border: 2px solid orange;
}
.languages {
  border: 1px solid;
  text-align: center;
}
